<div class="home-container">
  <div class="image-container">
    <div class="navigation">
      <h3>arcticode</h3>
      <div>
        <a (click)="scrollIntoViewTopps()">Våre produkter</a>
        <a (click)="scrollIntoViewContact()">Kontakt</a>
      </div>
    </div>
    <div class="logo-wrapper">
      <img src="/assets/images/arcticode.png" />
    </div>
  </div>
  <div class="topps-container" #topps>
    <div class="images-container">
      <img class="topps-icon" width="300px" src="/assets/images/topps-icon.png" />
    </div>
  </div>
  <div class="topps-container">
    <div class="images-container">
      <img class="topps-image" src="/assets/images/topps-places.png" />
      <img class="topps-image" src="/assets/images/topps-menu.png" />
      <img class="topps-image" src="/assets/images/topps-checkout.png" />
      <img class="topps-image" src="/assets/images/topps-map.png" />
    </div>
  </div>

  <div class="topps-container space-around">
    <div class="images-container space-evenly">
      <img class="image-padding" src="/assets/images/topps-admin-order-1.png" />
      <img class="image-padding" src="/assets/images/topps-admin-order.png" />
    </div>
    <div class="images-container space-evenly">
      <img class="image-padding" src="/assets/images/topps-admin-products.png" />
      <img class="image-padding" src="/assets/images/topps-admin-settings.png" />
    </div>
  </div>

  <div class="contact-container" #contact>
    <div>
      <h1>Kontakt</h1>
      <h3>
        Har du spørsmål relatert til produktene våre eller ønsker å knytte kontakt?
      </h3>
      <h3 class="no-margin">
        Send mail til
        <a href="mailto:sales@topps.app">sales@topps.app</a>
        og vi kommer tilbake til deg så fort vi kan!
      </h3>
    </div>
    <!-- <img src="/assets/images/furu.png" /> -->
  </div>
</div>
