import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'arctic-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('topps') toppsElement: ElementRef;
  @ViewChild('contact') contactElement: ElementRef;
  constructor() {}

  ngOnInit(): void {}

  scrollIntoViewTopps() {
    this.toppsElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
  scrollIntoViewContact() {
    this.contactElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
